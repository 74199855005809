<template>
    <Toast />
    <div class="p-grid nested-grid p-jc-between p-p-6">
        <div class="p-col-12">
            <router-link to='/shop/0'>
           <Button class="p-button-outlined p-button-secondary" type="button" label="Вернуться к товарам" icon="pi pi-angle-left"/>
           </router-link>
           <router-view></router-view>
        </div>
        <div class="product-container">
            <div class="product-image">
                <img :src="product.image_url" alt="">
            </div>
            <div class="product-info">
                <div class="p-grid">
                    <div class="p-col-12">
                        <div class="product-name"><b>{{product.name}}</b></div>
                        <div class="product-price">Цена <b>{{product.price}}</b> грн.</div>
                    </div>
                    <div class="p-col-12">
                        <div class="product-factory">{{product.factory}}</div>
                    </div>
                    <div class="p-col-12">
                        <div class="product-description">{{product.description}}</div>
                    </div>
                    <div class="p-col-12">
                        <div class="product-size">Объем {{product.size}} л.</div>
                        <div class="product-strong">Крепость {{product.strong}} об.</div>
                    </div>
                    <div class="p-col-12 card-btn">
                        <span class="p-buttonset">
                            <Button label="Добавить в корзину" class="p-button-outlined p-button-success" icon="pi pi-shopping-cart" @click="adToCart(product)"/>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProductService from '../service/ProductService';
import OrderService from '../service/OrderService';
import { mapState, mapMutations } from 'vuex';

export default {
    props: ['id'],
    data () {
        return {
            product: {},
            product_id: '',
        }
    },
    productService: null,
    orderService: null,
    created() {
		this.productService = new ProductService();
        this.orderService = new OrderService();
        this.orderService.order = JSON.parse(JSON.stringify(this.globalOrder));
	},
    
    mounted() {
		this.product_id = this.$route.params.id;
		if(this.product_id){
			this.productService.getProduct(this.product_id).then((data) => {this.product = data});
		}
	},
    computed: mapState({
        globalOrder: state => state.orders.globalOrder,
		
    }),

    methods: {
        ...mapMutations([
			'setOrder',
			'setCartCount' // `this.increment()` будет вызывать `this.$store.commit('increment')`
		]),
        async adToCart(product) {
			await this.orderService.addProduct(product);
			this.setOrder(this.orderService.order);	
			this.setCartCount(this.orderService.order.total.quantity);
			this.$toast.add({severity: 'success', summary: 'Товар добавлен в корзину', detail: product.name, life: 1000});
		},
    }
}
</script>

<style scoped>
.product-image{
    
    flex: 0 1 40%;
}
.product-image img {
    display: block;
    margin: 0px auto;
    max-width: 80%;
}
.product-info {
    display: flex;
    align-items: center;
    flex: 0 0 50%;
}
.p-button-success {
    background: #6f9251;
    border: 1px solid #689F38;
}
.product-name {
    font-size: 16px;
    text-transform: uppercase;
    padding-bottom: 10px;
}

.product-container {
    display: flex;
}

@media screen and (max-width: 670px) {
.product-container {
    flex-direction: column;
}
.product-name {
    text-align: center;
}
.card-btn {
    text-align: end;
}
}
</style>
